import {Navbar, Dropdown} from "flowbite-react";
import {useLocation, useNavigate} from "react-router-dom";

import image from "../../assets/img/logo.jpeg";
import {UserAuth} from "../../context/AuthContext";
import {useState} from "react";

export const Header = () => {
    const {user, logOut} = UserAuth();
    const navigate = useNavigate();
    const [currentCity, setCurrentCitity] = useState(localStorage.getItem('City'));
    const handleSignOut = async () => {
        try {
            await logOut()
        } catch (error) {
            console.log(error)
        }
    }
    const location = useLocation();
    const storeCity = (city)=>{
        setCurrentCitity(city);
        localStorage.setItem('City', city);
        navigate(
            "/city/" +city
        )

    }
    return (
        <Navbar fluid={true} rounded={true} style={{'backgroundColor':'#79C059'}} className={location.pathname === "/" ?'py-0':''}>
            {location.pathname !== "/" && (
                <>
                    <Navbar.Brand className="hidden lg:block">
                        <img src={image} className="mr-3 sm:h-12" alt="Localbusiness Logo"/>
                        <Dropdown
                            inline
                            label={currentCity}
                        >
                            <Dropdown.Item  onClick={()=>storeCity('Hyderabad')}>
                                Hyderabad
                            </Dropdown.Item>
                            <Dropdown.Item  onClick={()=>storeCity('Sangareddy')}>
                                Sangareddy
                            </Dropdown.Item>
                            <Dropdown.Item  onClick={()=>storeCity('Mumbai')}>
                                Mumbai
                            </Dropdown.Item>
                            <Dropdown.Item  onClick={()=>storeCity('Bangalore')}>
                                Bangalore
                            </Dropdown.Item>
                        </Dropdown>
                    </Navbar.Brand>

                </>

            )}
            {location.pathname === "/" && (
                <Navbar.Brand className="hidden lg:block">
                    <img src={image} className="mr-3 sm:h-12" alt="Localbusiness Logo"/>
                </Navbar.Brand>
            )}

            {location.pathname === "/" && (
                <Navbar.Brand href="/" className="mr-3 sm:h-20"></Navbar.Brand>
            )}
            <Navbar.Toggle/>
            <Navbar.Collapse>

                <Navbar.Link
                    href="/"
                    className="font-extrabold text-white"
                >
                    Home
                </Navbar.Link>
                <Navbar.Link
                    href="/create-business"
                    className="font-extrabold text-white"
                >
                    Create Business
                </Navbar.Link>

                {user?.email && <Navbar.Link
                    href="/my-business"
                    className="font-extrabold text-white"
                >
                    My Business
                </Navbar.Link>}
                {user?.email && <Navbar.Link
                    href="/user-profile"
                    className="font-extrabold text-white"
                >
                    My account
                </Navbar.Link>}
                {user?.email ? (
                    <button
                        className="block py-2 pr-4 pl-3 md:p-0 border-b border-gray-100  text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:bg-transparent md:dark:hover:text-white font-extrabold text-white"
                        onClick={handleSignOut}>Logout</button>
                ) : (
                    <Navbar.Link
                        href="/login"
                        className="font-extrabold text-white"
                    >
                        Login
                    </Navbar.Link>
                )}
                {!user?.email ? (
                    <Navbar.Link
                        href="/register"
                        className="font-extrabold text-white"
                    >
                        Register
                    </Navbar.Link>
                ) : null}
            </Navbar.Collapse>
        </Navbar>
    );
};
