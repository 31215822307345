import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
    SearchBox,
    Hits,
    RefinementList,
    Pagination,
    Panel,
    Configure
} from 'react-instantsearch-dom';

import {useNavigate, useParams} from "react-router-dom";

import {Header} from "../layouts";
import './style.css';
import {useEffect} from "react";

const searchClient = algoliasearch(
    "QYUVWALFXL",
    "6fa402cd2480183ac85034b3a13ff289"
);
export const CityComponent = () => {
    const navigate = useNavigate();
    let {city} = useParams();
    useEffect(()=>{
        console.log('city');
    })
    function Hit({hit}) {
        return (
            <div key={Math.random()} className="flex justify-center">
                <div className="max-w-sm rounded-lg bg-white shadow-lg">
                    <button onClick={() =>
                        navigate(
                            "/city/" +
                            hit.city +
                            "/" +
                            hit.businessName.replace(/\s+/g, "-"),

                            {
                                state: {
                                    business: hit,
                                },
                            }
                        )
                    }>
                        {hit.image ? (
                            <img
                                className="h-48 w-96 object-cover p-1"
                                src={hit.image}
                                alt=""
                            />
                        ) : (
                            <img
                                className="h-48 w-96 object-cover p-1"
                                src="https://cdn-icons-png.flaticon.com/512/3885/3885073.png"
                                alt=""
                            />
                        )}
                    </button>
                    <div className="p-6">
                        <h5 className="mb-2 text-l font-medium text-gray-900 font-extrabold">
                            {hit.businessName}
                        </h5>
                        {hit.businessName.length <35 && (<br/>)}
                        <p className="mb-4 text-base text-gray-700">{hit.address.length > 20 ? hit.address.slice(0, 20) + '...' : hit.address}</p>
                        <button
                            onClick={() =>
                                navigate(
                                    "/city/" +
                                    hit.city +
                                    "/" +
                                    hit.businessName.replace(/\s+/g, "-"),

                                    {
                                        state: {
                                            business: hit,
                                        },
                                    }
                                )
                            }
                            type="button"
                            className="nline-flex items-center rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        >
                            View
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <main>
            <Header/>
            <InstantSearch
                searchClient={searchClient}
                indexName="DEV_LOCAL_BUSINESS"
                routing={true}
            >
                <div className="flex ">
                    <div className="w-48 mt-10 mx-10">
                        <Panel header="Colony">
                            <RefinementList attribute="colonyName" />
                        </Panel>
                    </div>
                    <div className="grow md:mx-8 pt-10 w-64">
                        <div className="container mx-auto">

                            <Configure analytics={false} filters={"city:" + city} />
                            <SearchBox className="mb-10" placeholder="Search here" />

                            <Hits hitComponent={Hit}/>
                            <Pagination className="Pagination" />
                        </div>
                    </div>
                </div>
            </InstantSearch>
        </main>
    );
};


