import {useContext, createContext, useEffect, useState} from 'react';
import axios from "axios";

const ColonyContext = createContext();

export const ColonyContextProvider = ({children}) => {
    const [colonyData, setColonyData] = useState([]);

    useEffect(() => {
        axios
            .get("https://us-central1-flaxdax-backend.cloudfunctions.net/city/colony")
            .then((res) => {
                const colonies = res.data;
                setColonyData(colonies);
            })
            .catch(() => {
                alert("There was an error while retrieving the data");
            });
    }, []);

    return (
        <ColonyContext.Provider value={{colonyData}}>
            {children}
        </ColonyContext.Provider>
    );
};

export const ColonyData = () => {
    return useContext(ColonyContext);
};
