import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {
    HomeComponent,
    CityComponent,
    BusinessComponent,
    CreateBusinessComponent,
    MyBusinessComponent,
    EditBusinessComponent,
} from "./components";
import {LoginComponent} from "./components/Auth/Login";
import {AuthContextProvider} from "./context/AuthContext";
import {ProfileComponent} from "./components/Profile";
import {CityContextProvider} from "./context/CityContext";
import {CategoryContextProvider} from "./context/CagegoryContext";
import {ColonyContextProvider} from "./context/ColonyContext";
import {RegisterComponent} from "./components/Auth/Register";

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomeComponent/>,
    },
    {
        path: "/login",
        element: <LoginComponent/>,
    },
    {
        path: "/register",
        element: <RegisterComponent/>,
    },
    {
        path: "/user-profile",
        element: <ProfileComponent/>,
    },
    {
        path: "/create-business",
        element: <CreateBusinessComponent/>,
    },
    {
        path: "/my-business",
        element: <MyBusinessComponent/>,
    },
    {
        path: "/city/:city",
        element: <CityComponent/>,
    },
    {
        path: "/city/:city/:business",
        element: <BusinessComponent/>,
    },
    {
        path: "/edit/:businessName",
        element: <EditBusinessComponent/>,
    },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AuthContextProvider>
            <CityContextProvider>
                <CategoryContextProvider>
                    <ColonyContextProvider>
                        <RouterProvider router={router}/>
                    </ColonyContextProvider>
                </CategoryContextProvider>
            </CityContextProvider>
    </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();