// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAvK-RHck_9h0jsTQ9nkU1qW8U5xxx6rtA",
    authDomain: "flaxdax-backend.firebaseapp.com",
    projectId: "flaxdax-backend",
    storageBucket: "flaxdax-backend.appspot.com",
    messagingSenderId: "742024495332",
    appId: "1:742024495332:web:ea2dbdfed461969fc7ecf1",
    measurementId: "G-RWYD5FH7DL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
export {storage, auth};
