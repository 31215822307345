import {useContext, createContext, useEffect, useState} from 'react';
import axios from "axios";

const CategoryContext = createContext();

export const CategoryContextProvider = ({children}) => {
    const [categoryData, setCategoryData] = useState([]);

    useEffect(() => {
        axios
            .get("https://us-central1-flaxdax-backend.cloudfunctions.net/category")
            .then((res) => {
                const cities = res.data.map((Category) => Category.name);
                setCategoryData(cities);
            })
            .catch(() => {
                alert("There was an error while retrieving the data");
            });
    }, []);

    return (
        <CategoryContext.Provider value={{categoryData}}>
            {children}
        </CategoryContext.Provider>
    );
};

export const CategoryData = () => {
    return useContext(CategoryContext);
};
