import React, {useState, Fragment} from 'react';
import {Header} from "../layouts";
import { getAuth, updatePassword } from "firebase/auth";
import { Dialog, Transition } from '@headlessui/react'

import {UserAuth} from "../../context/AuthContext";
import {Button, Modal} from "flowbite-react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {Configure, Hits, InstantSearch} from "react-instantsearch-hooks-web";
import algoliasearch from "algoliasearch/lite";
const searchClient = algoliasearch(
    "QYUVWALFXL",
    "6fa402cd2480183ac85034b3a13ff289"
);
export const ProfileComponent = () => {
    const {user} = UserAuth();
    const navigate = useNavigate();
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [businessDeleted, setBusinessDeleted] = useState(false);
    let [isOpen, setIsOpen] = useState(false)
    const [password, setPassword] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }
    const passwordChangeDialog = ()=>{
        return (
            <>
                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Choose new password
                                        </Dialog.Title>
                                        <form className="bg-white rounded-lg" onSubmit={(e)=>changePassword(e)}>
                                            <div className="flex items-center text-lg mb-6 md:mb-8">
                                                <svg className="absolute ml-3" width="24" viewBox="0 0 24 24">
                                                    <path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/>
                                                </svg>
                                                <input type="text" name={password} value={password} onChange={(e)=>setPassword(e.target.value)} className="bg-white pl-12 py-2 md:py-4 focus:outline-none w-full rounded-lg" placeholder="New password" />
                                            </div>
                                            <div className="flex items-center text-lg mb-6 md:mb-8">
                                                <svg className="absolute ml-3" viewBox="0 0 24 24" width="24">
                                                    <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"/>
                                                </svg>
                                                <input type="text" name={password} value={password} onChange={(e)=>setPassword(e.target.value)} className="bg-white pl-12 py-2 md:py-4 focus:outline-none w-full rounded-lg" placeholder="Confirm new password" />
                                            </div>
                                            <button type="submit" className="from-gray-700 to-gray-900 font-medium p-2 md:p-4 text-white uppercase w-full bg-[#14532D]  rounded-lg">{formSubmitted ? "Submitting..." : "Submit"}</button>
                                            {formSubmitted && <p>Password reset successfully.</p>}
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </>
        );
    }
    const deleteMyBusiness = (businessId) => {
        axios
            .delete("https://us-central1-flaxdax-backend.cloudfunctions.net/business/"+businessId)
            .then((res) => {
                console.log(res);
                setBusinessDeleted(true);
                setTimeout(()=>{
                    setShowDeleteModel(false);
                    setBusinessDeleted(false);
                    window.location.reload();
                },1500);
            })
            .catch(() => {
                alert("There was an error while retrieving the data");
            });
    };
    const changePassword = (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        const auth = getAuth();
        const user = auth.currentUser;
        updatePassword(user, password).then(() => {
            // Update successful.
            console.log('Password successfully changed');
            setFormSubmitted(false);
            closeModal();
            setPassword("");
        }).catch((error) => {
            // An error ocurred
            console.log(error);
        });
    };

    function Hit({hit}) {
        return (
            <div key={Math.random()} className="flex justify-center">
                <div className="max-w-sm rounded-lg bg-white shadow-lg">
                    <button onClick={() =>
                        navigate(
                            "/city/" +
                            hit.city +
                            "/" +
                            hit.businessName.replace(/\s+/g, "-"),

                            {
                                state: {
                                    business: hit,
                                },
                            }
                        )
                    }>
                        {hit.image ? (
                            <img
                                className="h-48 w-96 object-cover p-1"
                                src={hit.image}
                                alt=""
                            />
                        ) : (
                            <img
                                className="h-48 w-96 object-cover p-1"
                                src="https://cdn-icons-png.flaticon.com/512/3885/3885073.png"
                                alt=""
                            />
                        )}
                    </button>
                    <div className="p-6">
                        <h5 className="mb-2 text-l font-medium text-gray-900 font-extrabold">
                            {hit.businessName}
                        </h5>
                        {hit.businessName.length <35 && (<br/>)}
                        <p className="mb-4 text-base text-gray-700">{hit.address.length > 20 ? hit.address.slice(0, 20) + '...' : hit.address}</p>
                        <button
                            onClick={() =>
                                navigate(
                                    "/city/" +
                                    hit.city +
                                    "/" +
                                    hit.businessName.replace(/\s+/g, "-"),

                                    {
                                        state: {
                                            business: hit,
                                        },
                                    }
                                )
                            }
                            type="button"
                            className="nline-flex items-center rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        >
                            View
                        </button>
                        <button
                            onClick={() =>
                                navigate(
                                    "/edit" +
                                    "/" +
                                    hit.businessName.replace(/\s+/g, "-"),
                                    {
                                        state: {
                                            business: hit,
                                        },
                                    }
                                )
                            }
                            className="float-right bg-grey-light hover:bg-grey text-blue-600 font-bold py-2 px-4 rounded inline-flex items-center">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                            </svg>
                            <span>Edit</span>
                        </button>
                        <button
                            onClick={() =>
                                setShowDeleteModel(true)
                            }
                            className="float-right bg-grey-light hover:bg-grey text-red-600 font-bold py-2 px-4 rounded inline-flex items-center">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                            </svg>
                            <span>Delete</span>
                        </button>
                        <Modal
                            show={showDeleteModel}
                        >
                            <Modal.Body>
                                <div className="space-y-6">
                                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                        Are you sure you want to delete the business? </p>
                                    {businessDeleted && (<span>Business successfully deleted.</span>)}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={()=>deleteMyBusiness(hit.id)} color="failure">
                                    I accept
                                </Button>
                                <Button
                                    color="gray"
                                    onClick={() =>
                                        setShowDeleteModel(false)
                                    }
                                >
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <main>
            <Header/>
            <div className="bg-gray-200 font-sans w-full flex flex-row justify-center items-center pt-40">
                <div className="card w-1/2 mx-auto bg-white  shadow-xl hover:shadow">
                    <img className="w-32 mx-auto rounded-full -mt-20 border-8 border-white"
                         src={user?.photoURL ? user?.photoURL:'https://avatars.githubusercontent.com/u/67946056?v=4'} alt=""/>
                    <div className="text-center mt-2 text-3xl font-medium">{user?.displayName}</div>
                    <div className="text-center mt-2 font-light text-sm">{user?.email}</div>

                    <hr className="mt-8"/>
                    <div className="flex p-4">
                        <div className="w-1/2 text-center font-bold">
                            Email verified
                            {user?.emailVerified?(<button
                                className="text-green-600 "
                                style={{
                                    position: "relative",
                                    top: "5px",
                                    left: "5px"
                                }}>
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"/>
                                </svg>
                            </button>):(<button
                                className="text-red-600 "
                                style={{
                                    position: "relative",
                                    top: "5px",
                                    left: "5px"
                                }}>
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            </button>)}

                        </div>
                        <div className="w-1/2 text-center font-bold">
                            <button onClick={openModal}>Change Password</button>
                            {passwordChangeDialog()}
                        </div>
                        <div className="w-0 border border-gray-300">

                        </div>
                        <div className="w-1/2 text-center">
                            <span className="font-bold">Last signIn</span> <br/>{user?.metadata.lastSignInTime}
                        </div>
                    </div>

                </div>

            </div>
            <div className="bg-gray-200 font-sans w-full flex flex-row justify-center items-center pt-8">
                <InstantSearch searchClient={searchClient} indexName="DEV_LOCAL_BUSINESS">
                    <Configure hitsPerPage={12} analytics={false} filters={"userUid:" + user?.uid}/>

                    <Hits hitComponent={Hit}/>
                </InstantSearch>
            </div>
        </main>
    );
};
