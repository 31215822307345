import {Header} from "../layouts";
import {useEffect, useState} from "react";
import {ref, getDownloadURL, uploadBytesResumable} from "firebase/storage";

import marker from "../../assets/img/marker.jpeg";
import {GoogleMap, useJsApiLoader, Marker, InfoWindow} from '@react-google-maps/api';
import {Combobox} from "@headlessui/react";
import {UserAuth} from "../../context/AuthContext";
import {storage} from "../firebase";
import {useParams} from "react-router-dom";
import TagsInput from "react-tagsinput";
import axios from "axios";
import {Checkbox, Label} from "flowbite-react";

export const EditBusinessComponent = () => {
    const [categories, setCategories] = useState([]);
    const [business, setBusiness] = useState(null);
    const [imageUploadProgress, setImageUploadProgress] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [query, setQuery] = useState("");
    const [image, setImage] = useState("");
    const [businessName, setbusinessName] = useState("");
    const [colonyName, setColonyName] = useState("");
    const [phone, setPhone] = useState("");
    const [freelance, setFreelance] = useState(false);
    const [othercategory, setOthercategory] = useState("");
    const [address, setAddress] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [formSubmissionSucceed, setFormSubmissionSucceed] = useState(false);
    const [tags, setTags] = useState([]);
    let businessParam = useParams();
    const {user} = UserAuth();
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const [mapRef] = useState();
    const [isOpen, setIsOpen] = useState(true);
    const [, setInfoWindowData] = useState();
    const mapContainerStyle = {
        height: "400px",
        width: "100%"
    }
    const handleMarkerClick = (id, lat, lng, address) => {
        mapRef?.panTo({ lat, lng });
        setInfoWindowData({ id, address });
        setIsOpen(true);
    };
    const updateLatLng = (data)=>{
        setLat(data.lat);
        setLng(data.lng);
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAU6tqGspJWP5XYjvGZOYyUDfALzYlRbD4"
    })
    useEffect(()=>{
        axios
            .get("https://us-central1-flaxdax-backend.cloudfunctions.net/business/business/"+businessParam.businessName.replaceAll("-", " "))
            .then((res) => {
                setBusiness(res.data[0]);
                setbusinessName(res.data[0].businessName);
                setColonyName(res.data[0].colonyName);
                setAddress(res.data[0].address);
                setPhone(res.data[0].phone);
                setSelectedCategory(res.data[0].category);
                if(res.data[0].tags){
                    setTags(res.data[0].tags);
                }
                if(res.data[0].latitude){
                    setLat(res.data[0].latitude);
                    setLng(res.data[0].longitude);
                }
                if(res.data[0].freelance){
                    setFreelance(res.data[0].freelance);
                }
            })
            .catch(() => {
                alert("There was an error while retrieving the business record from server");
            });
        axios
            .get("https://us-central1-flaxdax-backend.cloudfunctions.net/category")
            .then((res) => {
                const categoriesData = res.data.map((Category) => Category.name);
                const unique = [...new Map(categoriesData.map((m) => [m, m])).values()];
                setCategories(unique);
            })
            .catch(() => {
                alert("There was an error while retrieving the data");
            });
    },[businessParam.businessName])
    let filteredcategorys =
        query === ""
            ? []
            : categories.filter((category) =>
                category.toLowerCase().includes(query.toLowerCase())
            ).length === 0
                ? ["Other"]
                : categories.filter((category) =>
                    category.toLowerCase().includes(query.toLowerCase())
                );

    const handleUploadImage = (event) => {
        event.preventDefault();
        if (event.target.files[0]) {
            const sotrageRef = ref(storage, `files/${event.target.files[0].name+Math.random(0,1)}`);
            const uploadTask = uploadBytesResumable(sotrageRef, event.target.files[0]);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setImageUploadProgress(prog);
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log("File available at", downloadURL);
                        setImage(downloadURL);
                        setImageUploadProgress(0);
                        setDisableSubmit(false)
                    });
                }
            );
        }
    };
    const submitForm = () => {
        setFormSubmitted(true);
        fetch("https://us-central1-flaxdax-backend.cloudfunctions.net/business", {
            method: "PUT", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                colonyName,
                category: othercategory === "" ? selectedCategory : othercategory,
                businessName,
                phone,
                address,
                image: image === "" ? business.image : image,
                originalImageId:"",
                sales: "",
                businessId: business.id,
                gallery:[],
                tags,
                latitude: lat,
                longitude: lng,
                freelance
            }),
        }).then(() => {
            setFormSubmitted(false);
            setFormSubmissionSucceed(true);
        });
    };
    return (
        <main>
            <Header/>
            {!user && (<div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                            Please login to create business
                        </h2>
                    </div>

                </div>
            </div>)}
            {user && (<div className="flex items-center justify-center">
                <div className="mx-auto w-full max-w-lg rounded-lg bg-white px-10 py-8 shadow-xl">
                    <div className="mx-auto max-w-md space-y-6">
                        <div>
                            <h2 className="text-2xl font-bold ">Edit business</h2>
                            <hr className="my-6"/>
                            <label className="text-sm font-bold uppercase opacity-70">
                                Business name
                            </label>
                            <input
                                name="businessName"
                                value={businessName}
                                onChange={(e) => setbusinessName(e.target.value)}
                                type="text"
                                className="mt-2 mb-4 w-full rounded bg-slate-200 p-3"
                            />
                            <label className="text-sm font-bold uppercase opacity-70">
                                Colony name
                            </label>
                            <input
                                type="text"
                                name="colonyName"
                                value={colonyName}
                                onChange={(e) => setColonyName(e.target.value)}
                                className="mt-2 mb-4 w-full rounded bg-slate-200 p-3"
                            />
                            <label className="text-sm font-bold uppercase opacity-70">
                                Business category
                            </label>
                            <Combobox
                                value={selectedCategory}
                                onChange={(category) => {
                                    setSelectedCategory(category);
                                }}
                                nullable
                            >
                                <Combobox.Input
                                    placeholder="Category"
                                    className="mt-2 mb-4 w-full rounded bg-slate-200 p-3"
                                    onChange={(event) => setQuery(event.target.value)}
                                />
                                <Combobox.Options>
                                    {filteredcategorys.slice(0,10).map((category) => (
                                        <Combobox.Option
                                            key={category}
                                            value={category}
                                            className="ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-black"
                                        >
                                            {category}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            </Combobox>
                            {selectedCategory === "Other" && (<>
                                    <label className="text-sm font-bold uppercase opacity-70">
                                        Other category
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => setOthercategory(e.target.value)}
                                        className="mt-2 mb-4 w-full rounded bg-slate-200 p-3"
                                    />
                                </>
                            )}
                            <label className="text-sm font-bold uppercase opacity-70">
                                Phone
                            </label>
                            <input
                                value={phone}
                                type="text"
                                name="phone"
                                onChange={(e) => setPhone(e.target.value)}
                                className="mt-2 mb-4 w-full rounded bg-slate-200 p-3"
                            />
                            <div className="flex items-center gap-2 mb-5">
                                <Checkbox
                                    id="freelance"
                                    defaultChecked={freelance}
                                    onChange={()=>setFreelance(!freelance)}
                                />
                                <Label htmlFor="freelance">
                                    Freelance ?
                                </Label>
                            </div>
                            <label className="text-sm font-bold uppercase opacity-70">
                                Address
                            </label>
                            <textarea
                                value={address}
                                id="message"
                                rows="4"
                                name="address"
                                onChange={(e) => setAddress(e.target.value)}
                                className="mt-2 mb-4 w-full rounded bg-slate-200 p-3"
                            ></textarea>
                            <TagsInput value={tags} maxTags={5} onChange={(selectedTags)=>setTags(selectedTags)} />

                            <div className="group relative z-0 mb-6 w-full">
                                <label
                                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                                    htmlFor="file_input"
                                >
                                    Upload file
                                </label>
                                <input
                                    className="block w-full cursor-pointer rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 focus:outline-none dark:border-gray-600 dark:text-gray-400 dark:placeholder-gray-400"
                                    id="file_input"
                                    type="file"
                                    onChange={(event) => handleUploadImage(event)}
                                />
                            </div>

                            {imageUploadProgress > 0 && (<h2>Uploading done {imageUploadProgress}%</h2>)}

                            {
                                isLoaded ? (
                                    <div className="mb-6">
                                        <GoogleMap
                                            id="marker-example"
                                            mapContainerStyle={mapContainerStyle}
                                            zoom={12}
                                            center={{
                                                lat: lat? lat: 17.6114632,
                                                lng: lng? lng: 78.0590235
                                            }}
                                        >
                                            <Marker
                                                icon={marker}
                                                onClick={() => {
                                                    handleMarkerClick(address, lat? lat: 17.6114632, lng? lng: 78.0590235, address);
                                                }}
                                                key={business?.id}
                                                position={{
                                                    lat: lat? lat: 17.6114632,
                                                    lng: lng? lng: 78.0590235
                                                }}
                                                onDragEnd={(t) => {
                                                    updateLatLng(t.latLng.toJSON());
                                                }}
                                                onLoad={() => {
                                                    handleMarkerClick(address, lat? lat: 17.6114632, lng? lng: 78.0590235, address);
                                                }}
                                                draggable={true}

                                            >
                                                {isOpen && (
                                                    <InfoWindow
                                                        onCloseClick={() => {
                                                            setIsOpen(false);
                                                        }}
                                                    >

                                                        <>
                                                            <h3>{businessName}</h3>
                                                        </>

                                                    </InfoWindow>
                                                )}
                                            </Marker>
                                        </GoogleMap>
                                    </div>

                                ) : <></>
                            }
                            <button
                                type="button"
                                onClick={submitForm}
                                className={disableSubmit ? "px-8 py-3 text-white bg-green-300 rounded focus:outline-none" : "px-8 py-3 text-white bg-green-600 rounded focus:outline-none"}
                                disabled={disableSubmit}
                            >
                                {formSubmitted ? "Submitting..." : "Save Business"}
                            </button>

                        </div>
                        {formSubmissionSucceed && (
                            <div
                                className="mb-4 mt-4 rounded-lg bg-blue-100 p-4 text-sm text-blue-700 dark:bg-blue-200 dark:text-blue-800"
                                role="alert"
                            >
                                Business successfully submitted.
                            </div>
                        )}
                        {image && (
                            <div className="flex flex-wrap justify-center">
                                <img
                                    src={image}
                                    className="p-1 bg-white border rounded max-w-sm"
                                    alt="..."
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>)}

        </main>
    );
};
