import 'react-tagsinput/react-tagsinput.css'
import Resizer from "react-image-file-resizer";
import {Header} from "../layouts";
import {useState, useEffect} from "react";
import {ref, getDownloadURL, uploadBytesResumable, deleteObject, getStorage} from "firebase/storage";
import TagsInput from 'react-tagsinput';
import {Combobox} from "@headlessui/react";
import {UserAuth} from "../../context/AuthContext";
import {storage} from "../firebase";
import {useNavigate} from "react-router-dom";
import {CityData} from "../../context/CityContext";
import {CategoryData} from "../../context/CagegoryContext";
import {ColonyData} from "../../context/ColonyContext";
import {Label, Select, Checkbox} from "flowbite-react";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import marker from "../../assets/img/marker.jpeg";

export const CreateBusinessComponent = () => {
    const navigate = useNavigate();
    const [cities, setCities] = useState([]);
    const [categories, setCategories] = useState([]);
    const [imageUploadProgress, setImageUploadProgress] = useState(0);
    const [selectedcategory, setSelectedcategory] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [query, setQuery] = useState("");
    const [queryCity, setQueryCity] = useState("");
    const [image, setImage] = useState("");
    const [gallery, setGallery] = useState([]);
    const [businessName, setbusinessName] = useState("");
    const [colonyName, setColonyName] = useState("");
    const [phone, setPhone] = useState("");
    const [freelance, setFreelance] = useState(false);
    const [othercategory, setOthercategory] = useState("");
    const [address, setAddress] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [formSubmissionSucceed, setFormSubmissionSucceed] = useState(false);
    const [tags, setTags] = useState([]);
    const [showAdditionalPhotos, setShowAdditionalPhotos] = useState(false);

    const {user} = UserAuth();
    const {cityData} = CityData();
    const {categoryData} = CategoryData();
    const {colonyData} = ColonyData();
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const [mapRef] = useState();
    const [, setInfoWindowData] = useState();
    const mapContainerStyle = {
        height: "400px",
        width: "100%"
    }
    const handleMarkerClick = (id, lat, lng, address) => {
        mapRef?.panTo({ lat, lng });
        setInfoWindowData({ id, address });
    };
    const updateLatLng = (data)=>{
        setLat(data.lat);
        setLng(data.lng);
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAU6tqGspJWP5XYjvGZOYyUDfALzYlRbD4"
    })
    useEffect(() => {
        setCities(cityData);
        const unique = [...new Map(categoryData.map((m) => [m, m])).values()];
        setCategories(unique);
    }, [cityData, categoryData]);
    let filteredCategories =
        query === ""
            ? []
            : categories.filter((category) =>
                category.toLowerCase().includes(query.toLowerCase())
            ).length === 0
                ? ["Other"]
                : categories.filter((category) =>
                    category.toLowerCase().includes(query.toLowerCase())
                );
    let filteredCities =
        queryCity === ""
            ? []
            : cities.filter((city) =>
                city.toLowerCase().includes(queryCity.toLowerCase())
            ).length === 0
                ? ["Other"]
                : cities.filter((sale) =>
                    sale.toLowerCase().includes(queryCity.toLowerCase())
                );
    const handleUploadGalleryImage = (event) => {
        event.preventDefault();
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    512,
                    512,
                    "JPEG",
                    100,
                    0,
                    (uri) => {
                        const sotrageRef = ref(storage, `files/${event.target.files[0].name + Math.random(0, 1)}`);
                        const uploadTask = uploadBytesResumable(sotrageRef, uri);

                        uploadTask.on(
                            "state_changed",
                            (snapshot) => {
                                const prog = Math.round(
                                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                );
                                setImageUploadProgress(prog);
                            },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    let tempGallery = gallery;
                                    tempGallery.push(downloadURL);
                                    setGallery(tempGallery);
                                    setImage(downloadURL);
                                    setImageUploadProgress(0);
                                    setDisableSubmit(false)
                                });
                            }
                        );
                    },
                    "file",
                    200,
                    200
                );
            } catch (err) {
                console.log(err);
            }

        }

    };
    const handleUploadImage = (event) => {
        event.preventDefault();
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    512,
                    512,
                    "JPEG",
                    100,
                    0,
                    (uri) => {
                        const sotrageRef = ref(storage, `files/${event.target.files[0].name+Math.random(0,1)}`);
                        const uploadTask = uploadBytesResumable(sotrageRef, uri);

                        uploadTask.on(
                            "state_changed",
                            (snapshot) => {
                                const prog = Math.round(
                                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                );
                                setImageUploadProgress(prog);
                            },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    console.log("File available at", downloadURL);
                                    setImage(downloadURL);
                                    setImageUploadProgress(0);
                                    setDisableSubmit(false)
                                });
                            }
                        );
                    },
                    "file",
                    200,
                    200
                );
            } catch (err) {
                console.log(err);
            }

        }
    };
    const deleteImage = (img)=>{
        setImage(null);
        let tempGallery = gallery;
        tempGallery = tempGallery.filter(a => a === img);
        setGallery(tempGallery);
        const storage = getStorage();

        const desertRef = ref(storage, img);

        deleteObject(desertRef).then((del) => {
            // File deleted successfully
            console.log(del);
        }).catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error);
        });

    }
    const submitForm = () => {
        setFormSubmitted(true);
        fetch("https://us-central1-flaxdax-backend.cloudfunctions.net/business", {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                colonyName,
                category: othercategory === "" ? selectedcategory : othercategory,
                city: selectedCity,
                businessName: businessName.replaceAll("/", ""),
                phone,
                address,
                image:image,
                gallery,
                originalImageId: "",
                sales: "",
                userUid: user.uid,
                tags,
                latitude: lat,
                longitude: lng,
                freelance
            }),
        }).then(() => {
            setFormSubmitted(false);
            setFormSubmissionSucceed(true);

            setTimeout(()=>{
                navigate(
                    "/my-business"
                )
            }, 1200)
        });
    };
    return (
        <main>
            <Header/>
            {!user && (<div className="flex items-center justify-center mt-10 mb-10">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                            Please login to create business
                        </h2>
                        <p className="mt-6 text-center text-2xl font-bold tracking-tight text-green-600">
                            <button onClick={() => navigate("/login")}>Login</button>
                        </p>
                    </div>
                </div>
            </div>)}
            {user && (<div className="max-w-2xl mx-auto bg-gray-100 p-16 mt-10">
                <h2 className="text-2xl font-bold ">Create a business</h2>

                <form>
                    <div className="grid gap-6 mb-6 lg:grid-cols-2">
                        <div>
                            <label className="text-sm font-bold uppercase opacity-70">
                                Business name *
                            </label>
                            <input
                                placeholder="Business name"
                                name="businessName"
                                onChange={(e) => setbusinessName(e.target.value)}
                                type="text"
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label className="text-sm font-bold uppercase opacity-70">
                                Business category *
                            </label>
                            <Combobox
                                value={selectedcategory}
                                onChange={(category) => {
                                    setSelectedcategory(category);
                                }}

                            >
                                <Combobox.Input
                                    placeholder="Category"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

                                    onChange={(event) => setQuery(event.target.value)}
                                />
                                <Combobox.Options>
                                    {filteredCategories.slice(0, 10).map((category) => (
                                        <Combobox.Option
                                            key={category}
                                            value={category}
                                            className="ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-black"
                                        >
                                            {category}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            </Combobox>
                            {selectedcategory === "Other" && (<>
                                    <label className="text-sm font-bold uppercase opacity-70">
                                        Other category
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => setOthercategory(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    />
                                </>
                            )}
                        </div>

                        <div>
                            <label className="text-sm font-bold uppercase opacity-70">
                                Phone *
                            </label>
                            <input
                                type="text"
                                name="phone"
                                placeholder="+91 xxxxxxxxxx"
                                required
                                onChange={(e) => setPhone(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            />
                        </div>

                        <div>
                            <label className="text-sm font-bold uppercase opacity-70">
                                Cities
                            </label>
                            <Combobox
                                value={selectedCity}
                                onChange={(city) => {
                                    setSelectedCity(city);
                                }}
                                nullable
                            >
                                <Combobox.Input
                                    placeholder="City"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    onChange={(event) => setQueryCity(event.target.value)}
                                />
                                <Combobox.Options>
                                    {filteredCities.slice(0, 10).map((city) => (
                                        <Combobox.Option
                                            key={city}
                                            value={city}
                                            className="ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-black"
                                        >
                                            {city}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            </Combobox>
                        </div>
                        <div>
                            {colonyData.filter(colony => colony.city === selectedCity).length > 0 && <div id="select">
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="countries"
                                        value="Select your country"
                                    />
                                </div>
                                <Select
                                    name="colonyName"
                                    onChange={(e) => setColonyName(e.target.value)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    id="countries"
                                    required={true}
                                >
                                    {colonyData.filter(colony => colony.city === selectedCity).map(colony => (<option>
                                        {colony.name}
                                    </option>))}
                                </Select>
                            </div>}
                            {colonyData.filter(colony => colony.city === selectedCity).length === 0 && <>
                                <label className="text-sm font-bold uppercase opacity-70">
                                    Colony name
                                </label>
                                <input
                                    value={colonyName}
                                    type="text"
                                    name="colonyName"
                                    onChange={(e) => setColonyName(e.target.value)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </>
                            }
                        </div>
                    </div>
                    <div className="flex items-center gap-2 mb-5">
                        <Checkbox
                            id="freelance"
                            defaultChecked={false}
                            onChange={()=>setFreelance(!freelance)}
                        />
                        <Label htmlFor="freelance">
                            Freelance ?
                        </Label>
                    </div>
                    <div className="grid gap-6 mb-6 lg:grid-cols-1">
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">TAG</label>
                            <TagsInput
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={tags} maxTags={5} onChange={(selectedTags) => setTags(selectedTags)}/>
                        </div>
                    </div>
                    <div className="mb-6">

                        <label className="text-sm font-bold uppercase opacity-70">
                            Address
                        </label>
                        <textarea
                            id="message"
                            rows="4"
                            name="address"
                            onChange={(e) => setAddress(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                    </div>
                    <div className={image?"hidden":"mb-6"}>
                        <div className="group relative z-0 mb-6 w-full">
                            <label
                                className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                                htmlFor="file_input"
                            >
                                Upload business photo
                            </label>
                            <input
                                className="block w-full cursor-pointer rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 focus:outline-none dark:border-gray-600 dark:text-gray-400 dark:placeholder-gray-400"
                                id="file_input"
                                type="file"
                                onChange={(event) => handleUploadImage(event)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <Checkbox
                            id="accept"
                            defaultChecked={false}
                            onChange={()=>setShowAdditionalPhotos(!showAdditionalPhotos)}
                        />
                        <Label htmlFor="accept">
                            Want to add more images(optional)?
                        </Label>
                    </div>
                    <div className={showAdditionalPhotos ? "flex items-center justify-center":"flex items-center justify-center hidden"}>
                        <div className="rounded-md border border-gray-100 bg-white p-4 shadow-md m-2">
                            <label htmlFor="business_gallery_image_1" className="flex flex-col items-center gap-2 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="h-10 w-10 fill-white stroke-indigo-500" viewBox="0 0 24 24"
                                     stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                </svg>
                                <span className="ext-gray-600 font-medium text-xs text-center">Upload photo</span>
                            </label>
                            <input
                                id="business_gallery_image_1"
                                type="file"
                                onChange={(event) => handleUploadGalleryImage(event)} className="hidden"/>
                        </div>
                           <div className="rounded-md border border-gray-100 bg-white p-4 shadow-md m-2">
                               <label htmlFor="business_gallery_image_2" className="flex flex-col items-center gap-2 cursor-pointer">
                                   <svg xmlns="http://www.w3.org/2000/svg"
                                        className="h-10 w-10 fill-white stroke-indigo-500" viewBox="0 0 24 24"
                                        stroke="currentColor" strokeWidth="2">
                                       <path strokeLinecap="round" strokeLinejoin="round"
                                             d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                   </svg>
                                   <span className="ext-gray-600 font-medium text-xs text-centerr">Upload photo</span>
                               </label>
                               <input
                                   id="business_gallery_image_2"
                                   type="file"
                                   onChange={(event) => handleUploadGalleryImage(event)} className="hidden"/>
                           </div>
                           <div className="rounded-md border border-gray-100 bg-white p-4 shadow-md m-2">
                               <label htmlFor="business_gallery_image_3" className="flex flex-col items-center gap-2 cursor-pointer">
                                   <svg xmlns="http://www.w3.org/2000/svg"
                                        className="h-10 w-10 fill-white stroke-indigo-500" viewBox="0 0 24 24"
                                        stroke="currentColor" strokeWidth="2">
                                       <path strokeLinecap="round" strokeLinejoin="round"
                                             d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                   </svg>
                                   <span className="text-gray-600 font-medium text-xs text-center">Upload photo</span>
                               </label>
                               <input
                                   id="business_gallery_image_3"
                                   type="file"
                                   onChange={(event) => handleUploadGalleryImage(event)} className="hidden"/>
                           </div>
                    </div>
                    {imageUploadProgress > 0 && (<h2>Uploading done {imageUploadProgress}%</h2>)}
                    {
                        isLoaded ? (
                            <div className="mb-6 mt-6">
                                <GoogleMap
                                    id="marker-example"
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={12}
                                    center={{
                                        lat: lat? lat: 17.6114632,
                                        lng: lng? lng: 78.0590235
                                    }}
                                >
                                    <Marker
                                        icon={marker}
                                        onClick={() => {
                                            handleMarkerClick(address, lat? lat: 17.6114632, lng? lng: 78.0590235, address);
                                        }}
                                        position={{
                                            lat: lat? lat: 17.6114632,
                                            lng: lng? lng: 78.0590235
                                        }}
                                        onDragEnd={(t) => {
                                            console.log(t.latLng.toJSON())
                                            updateLatLng(t.latLng.toJSON());
                                        }}
                                        onLoad={() => {
                                            handleMarkerClick(address, lat? lat: 17.6114632, lng? lng: 78.0590235, address);
                                        }}
                                        draggable={true}

                                    >
                                    </Marker>
                                </GoogleMap>
                            </div>

                        ) : <></>
                    }
                    <div className="container pt-10 px-10 mx-0 min-w-full grid place-items-center">
                        <button
                            type="button"
                            onClick={submitForm}
                            className={disableSubmit ? "px-8 py-3 text-white bg-green-300 rounded focus:outline-none items-center" : "px-8 py-3 text-white bg-green-600 rounded focus:outline-none items-center"}
                            disabled={disableSubmit}
                        >
                            {formSubmitted ? "Submitting..." : "Create Business"}
                        </button>
                    </div>
                </form>
                {formSubmissionSucceed && (
                    <div
                        className="mb-4 mt-4 rounded-lg bg-blue-100 p-4 text-sm text-blue-700 dark:bg-blue-200 dark:text-blue-800"
                        role="alert"
                    >
                        Business successfully submitted.
                    </div>
                )}
                {image && (
                    <div className="flex flex-wrap justify-center mt-6">

                        <img
                            src={image}
                            className="p-1 bg-white border rounded max-w-sm"
                            alt="..."
                        />
                        <button className="text-red-600 w-5" onClick={(e)=>{
                            deleteImage(image);
                        }}>
                            <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                )}
            </div>)}

        </main>
    );
};