import React, {useEffect, useState} from 'react';
import {Header} from "../../layouts";
import {useNavigate} from 'react-router-dom';
import image from '../../../assets/img/bg_map.png';
import userImg from '../../../assets/img/user.svg';
import {UserAuth} from "../../../context/AuthContext";
import axios from "axios";
export const RegisterComponent = () => {
    const {user} = UserAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [mobile, setMobile] = useState("");

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formSubmissionSucceed, setFormSubmissionSucceed] = useState(false);
    const [formSubmissionFailed, setFormSubmissionFailed] = useState(false);
    const [formSubmissionFailMsg, setFormSubmissionFailMsg] = useState("");
    useEffect(() => {
        if (user != null) {
            navigate('/');
        }
    }, [user, navigate]);

    const submitRegister = (e)=>{
        e.preventDefault();

        setFormSubmitted(true);
        axios.post('https://us-central1-flaxdax-backend.cloudfunctions.net/user', {
            email: email,
            phoneNumber: mobile,
            password: password,
            displayName: username,
            photoURL: "https://lh3.googleusercontent.com/a/AGNmyxYlDftseTpmAacm2whotUKi41rdQlodlBE8LP4ZOw=s83-c-mo",
        }).then(() => {
            setFormSubmitted(false);
            setFormSubmissionSucceed(true);
            setFormSubmissionFailMsg("");
            setFormSubmissionFailed(false);
        }).catch((e) => {
            setFormSubmitted(false);
            setFormSubmissionFailed(true);
            setFormSubmissionSucceed(false);
            setFormSubmissionFailMsg(e.response.data.error.message);
        })
    }
    return (
        <main>
            <Header/>
            <div className="h-screen overflow-hidden flex items-center justify-center" style={{'backgroundImage':`url("${image}")`}}>
                <div className="lg:w-6/12 md:7/12 w-11/12 shadow-3xl pt-36">
                    <div className="shadow-lg">
                        <div className="bg-white absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-4 md:p-8 m-2 border-4 border-black-300">
                            <img src={userImg} alt="user"/>
                        </div>
                        <form className="p-12 md:p-24 bg-white rounded-lg" onSubmit={(e)=>submitRegister(e)}>
                            <div className="flex items-center text-lg mb-6 md:mb-8">
                                <svg fill="none" className="absolute ml-3" width="24" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/>
                                </svg>
                                <input type="text" id="username" name={username} onChange={(e)=>setUsername(e.target.value)} className="bg-white pl-12 py-2 md:py-4 focus:outline-none w-full rounded-lg" placeholder="Username" />
                            </div>
                            <div className="flex items-center text-lg mb-6 md:mb-8">
                                <svg fill="none" className="absolute ml-3" width="24" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"></path>
                                </svg>
                                <input type="text" id="email" name={email} onChange={(e)=>setEmail(e.target.value)} className="bg-white pl-12 py-2 md:py-4 focus:outline-none w-full rounded-lg" placeholder="Email" />
                            </div>
                            <div className="flex items-center text-lg mb-6 md:mb-8">
                                <svg fill="none" className="absolute ml-3" width="24" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"/>
                                </svg>
                                <input type="password" id="password" onChange={(e)=>setPassword(e.target.value)}  name={password} className="bg-white pl-12 py-2 md:py-4 focus:outline-none w-full rounded-lg" placeholder="Password" />
                            </div>

                            <div className="flex items-center text-lg mb-6 md:mb-8">
                                <svg fill="none" className="absolute ml-3" width="24" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"></path>
                                </svg>
                                <input type="tel" id="mobile" name={mobile} onChange={(e)=>setMobile(e.target.value)} className="bg-white pl-12 py-2 md:py-4 focus:outline-none w-full rounded-lg" placeholder="Mobile" />
                            </div>
                            <button type="submit" className="from-gray-700 to-gray-900 font-medium p-2 md:p-4 text-white uppercase w-full bg-[#14532D]  rounded-lg">{formSubmitted ? "Submitting..." : "Register"}</button>
                            {formSubmissionSucceed && (
                                <div
                                    className="mb-4 mt-4 rounded-lg bg-blue-100 p-4 text-sm text-blue-700 dark:bg-blue-200 dark:text-blue-800"
                                    role="alert"
                                >
                                    User successfully registered.
                                </div>
                            )}
                            {formSubmissionFailed && (
                                <div
                                    className="mb-4 mt-4 rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
                                    role="alert"
                                >
                                    {formSubmissionFailMsg}
                                </div>
                            )}
                        </form>

                    </div>
                </div>
            </div>
        </main>
    );
};
