import {useContext, createContext, useEffect, useState} from 'react';
import axios from "axios";

const CityContext = createContext();

export const CityContextProvider = ({children}) => {
    const [cityData, setcityData] = useState([]);

    useEffect(() => {
        axios
            .get("https://us-central1-flaxdax-backend.cloudfunctions.net/city")
            .then((res) => {
                const cities = res.data.map((city) => city.name);
                setcityData(cities);
            })
            .catch(() => {
                alert("There was an error while retrieving the data");
            });
    }, []);

    return (
        <CityContext.Provider value={{cityData}}>
            {children}
        </CityContext.Provider>
    );
};

export const CityData = () => {
    return useContext(CityContext);
};
