import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

import {Header} from "../layouts";
import axios from "axios";
import './style.css';
export const HomeComponent = () => {

    const navigate = useNavigate();
    const [business, setBusiness] = useState([]);
    useEffect(() => {
        const userCity = localStorage.getItem('City');
        if(userCity){
            navigate(
                "/city/" +userCity
            )
        }
        axios
            .get("https://us-central1-flaxdax-backend.cloudfunctions.net/business/")
            .then((res) => {
                setBusiness(res.data.slice(0,9))
            })
            .catch(() => {
                alert("There was an error while retrieving the data");
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, ['']);

    const storeCity = (city)=>{
        localStorage.setItem('City', city);
    }
    return (
        <main>
            <Header/>
            <div id="staticModal" data-modal-backdrop="static" tabIndex="-1" aria-hidden="true"
                 className="bg-gray-300 bg-opacity-80 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex">
                <div className="relative w-full max-w-3xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Select your city
                            </h3>
                        </div>

                        <div className="grid grid-cols-2 md:grid-cols-2 gap-4 p-6">
                            <a href='/city/Hyderabad' onClick={()=>storeCity('Hyderabad')}>
                                <img className="h-60 w-100 rounded-lg hue-rotate-15"
                                     src="https://static.toiimg.com/thumb/msid-24183773,width-1070,height-580,resizemode-75,imgsize-24183773,pt-32,y_pad-40/24183773.jpg" alt="" />
                                <p className="text-center">Hyderabad</p>
                            </a>
                            <a href='/city/Sangareddy' onClick={()=>storeCity('Sangareddy')}>
                                <img className="h-60 w-100 rounded-lg hue-rotate-15"
                                     src="https://cdn.s3waas.gov.in/s3854d9fca60b4bd07f9bb215d59ef5561/uploads/bfi_thumb/2018030873-olwahdsml1zl3pfkgic17md35mi9qiv0afxalmvs2o.jpg" alt="" />
                                <p className="text-center">Sangareddy</p>
                            </a>
                            <a href='/city/Mumbai' onClick={()=>storeCity('Mumbai')}>
                                <img className="h-60 w-100 rounded-lg hue-rotate-15"
                                     src="https://media.istockphoto.com/id/1307189136/sv/foto/gateway-of-india-mumbai-maharashtra-monument-landm%C3%A4rke-k%C3%A4nd-plats-magnifik-utsikt-utan.jpg?s=612x612&w=0&k=20&c=7sCNkd4dcmB6YScdvjHhXw-C5qDAbo2fElAml2LwaoU=" alt="" />
                                <p className="text-center">Mumbai</p>
                            </a>
                            <a href='/city/Bangalore' onClick={()=>storeCity('Bangalore')}>
                                <img className="h-60 w-100 rounded-lg hue-rotate-15"
                                     src="https://media.istockphoto.com/id/1192261427/sv/foto/vidhan-soudha-bangalore.jpg?s=612x612&w=0&k=20&c=_JYawlvFPTqIihBJzngqoCMOZNRN8KITnIYQAbGHbVg=" alt="" />
                                <p className="text-center">Bangalore</p>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
            <div className="flex ">
                <div className="hidden h-14 w-28 flex-none md:invisible lg:block">
                    03
                </div>
                <div className="hidden h-14 w-28 flex-none md:invisible lg:block">
                    03
                </div>
                <div className="grow md:mx-8 pt-10">
                    <div className="grid grid-cols-3 gap-3">
                        {business && business.map(hit=>(
                            <div key={Math.random()} className="max-w-sm rounded-lg bg-white shadow-lg">
                            <button onClick={() =>
                            navigate(
                            "/city/" +
                            hit.city +
                            "/" +
                            hit.businessName.replace(/\s+/g, "-"),

                        {
                            state: {
                            business: hit,
                        },
                        }
                            )
                        }>
                        {hit.image ? (
                            <img
                                className="h-48 w-96 object-cover p-1"
                                src={hit.image}
                                alt=""
                            />
                        ) : (
                            <img
                                className="h-48 w-96 object-cover p-1"
                                src="https://cdn-icons-png.flaticon.com/512/3885/3885073.png"
                                alt=""
                            />
                        )}
                    </button>
                    <div className="p-6">
                        <h5 className="mb-2 text-l font-medium text-gray-900 font-extrabold">
                            {hit.businessName}
                        </h5>
                        {hit.businessName.length <35 && (<br/>)}
                        <p className="mb-4 text-base text-gray-700">{hit.address.length > 20 ? hit.address.slice(0, 20) + '...' : hit.address}</p>
                        <button
                            onClick={() =>
                                navigate(
                                    "/city/" +
                                    hit.city +
                                    "/" +
                                    hit.businessName.replace(/\s+/g, "-"),

                                    {
                                        state: {
                                            business: hit,
                                        },
                                    }
                                )
                            }
                            type="button"
                            className="nline-flex items-center rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        >
                            View
                        </button>
                    </div>
                </div>
                        ))}
                    </div>
                </div>
                <div className="hidden h-14 w-28 flex-none sm:invisible md:invisible lg:block">
                    03
                </div>
            </div>
        </main>
    );
};
