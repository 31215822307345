import {Header} from "../layouts";
import {useLocation, Link } from "react-router-dom";
import {Breadcrumb, Table} from "flowbite-react";
import ImageGallery from 'react-image-gallery';
import {useEffect, useState} from "react";
import {HiHome} from "react-icons/hi";
import image from "../../assets/img/marker.jpeg";
import {GoogleMap, useJsApiLoader, Marker, InfoWindow} from '@react-google-maps/api';
import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
    Hits, Configure, RefinementList
} from "react-instantsearch-hooks-web";

import {UserAuth} from "../../context/AuthContext";
import axios from "axios";
import './style.css';
const searchClient = algoliasearch(
    "QYUVWALFXL",
    "6fa402cd2480183ac85034b3a13ff289"
);
export const BusinessComponent = () => {
    let [city, setCity] = useState("");
    const location = useLocation();
    const {user} = UserAuth();
    const [commentMessage, setCommentMessage] = useState("");
    const business = location.state.business;
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const [images, setImages] = useState([]);
    const [comments, setComments] = useState([]);
    const [mapRef] = useState();
    const [isOpen, setIsOpen] = useState(true);
    const [, setInfoWindowData] = useState();
    const mapContainerStyle = {
        height: "400px",
        width: "100%"
    }
    useEffect(()=>{
        setCity(localStorage.getItem('City'));
    }, [])

    const handleMarkerClick = (id, lat, lng, address) => {
        mapRef?.panTo({ lat, lng });
        setInfoWindowData({ id, address });
        setIsOpen(true);
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAU6tqGspJWP5XYjvGZOYyUDfALzYlRbD4"
    })
    useEffect(() => {
        if(business.latitude){
            setLat(business.latitude);
            setLng(business.longitude);
        }

        axios.get('https://us-central1-flaxdax-backend.cloudfunctions.net/comment/'+business.objectID).then(res=>{
            if(res.data.success){
                setComments(res.data.comments);
            }
        })

        if (business.gallery && business.gallery.length > 0) {
            const tempImg = [];
            business.gallery.map(img => {
                tempImg.push({
                    original: img
                })
                return false;
            });
            tempImg.push({
                original: business.image
            })
            setImages(tempImg);
        } else {
            const tempImg = [];
            tempImg.push({
                original: business.image
            })
            setImages(tempImg);
        }
    }, [business.gallery, business.image, business.businessId, business.objectID, business]);
    const transformItems = (items) => {
        return items.sort(() => Math.random() - 0.5).slice(0,6);
    };

    function Hit({hit}) {
        return (<Link to={ "/city/" +
            hit.city +
            "/" +
            hit.businessName.replace(/\s+/g, "-")} state={{  business: hit}}>
            <div key={Math.random()} className="flex justify-center">
                <div className="max-w-sm rounded-lg bg-white shadow-lg">
                    <button onClick={() =>{
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });}
                    }>
                        {hit.image ? (
                            <img
                                className="h-48 w-96 object-cover p-1"
                                src={hit.image}
                                alt=""
                            />
                        ) : (
                            <img
                                className="h-48 w-96 object-cover p-1"
                                src="https://cdn-icons-png.flaticon.com/512/3885/3885073.png"
                                alt=""
                            />
                        )}
                    </button>

                    <div className="p-6">
                        <h5 className="mb-2 text-l font-medium text-gray-900">
                            {hit.businessName}
                        </h5>
                        {hit.businessName.length <35 && (<br/>)}
                        <p className="mb-4 text-base text-gray-700">{hit.address.length > 20 ? hit.address.slice(0, 20) + '...' : hit.address}</p>
                    </div>
                </div>
            </div></Link>
        );
    }
    const createComment = (e)=>{
        e.preventDefault();
        const date = new Date();
        const [month, day, year] = [
            date.getMonth(),
            date.getDate(),
            date.getFullYear(),
        ];
        const data = {
            'commentedBy': user.uid,
            'businessId': business.businessId,
            'comment': commentMessage,
            'commentedUserPic': user?.photoURL ? user?.photoURL:'https://avatars.githubusercontent.com/u/67946056?v=4',
            'commentedUserName': user?.displayName,
            'commentedOn': day +'/'+month+'/'+year
        }
        const oldComments = comments;
        oldComments.push(data);
        setComments(oldComments);
        setCommentMessage("");
        axios.post('https://us-central1-flaxdax-backend.cloudfunctions.net/comment/', data).then(res=>{
            console.log(res);
        })
    };
    return (
        <main>
            <Header/>
            <div class="mx-auto grid grid-cols-12 gap-2">
                <div class="col-span-12 p-12 sm:col-span-8">
                    <Breadcrumb aria-label="Default breadcrumb example">
                        <Breadcrumb.Item href="/" icon={HiHome}>
                            Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href={"/city/" + business.city}>
                            {business.city}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{business.businessName}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="justify-content-center text-center justify-items-center m-auto">
                        <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl dark:text-white">
                            {business.businessName}
                        </h1>
                        <Table>
                            <Table.Body className="divide-y">
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell
                                        className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Colony
                                    </Table.Cell>
                                    <Table.Cell>
                                        {business.colonyName}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell
                                        className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Address
                                    </Table.Cell>
                                    <Table.Cell>
                                        {business.address}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell
                                        className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Phone
                                    </Table.Cell>
                                    <Table.Cell>
                                        {business.phone}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell
                                        className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Category
                                    </Table.Cell>
                                    <Table.Cell>
                                        {business.category}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell
                                        className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        Last Updated
                                    </Table.Cell>
                                    <Table.Cell>
                                        {business.updatedAt?.substring(0, 10)}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                    {
                        <div className="mt-6 mb-6" style={{
                            height: "auto",
                            width: "auto",
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <ImageGallery sizes="512" showThumbnails={false} showPlayButton={false}
                                          items={images}/>
                        </div>
                    }
                    <div className="flex">
                        <div className="m-auto">
                            <section className="place-items-center" id="section-related-resources">
                                <h1 className="mb-2 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl dark:text-white">
                                    Similar Business
                                </h1>
                                <div className="mb-1 grid place-items-center">
                                    <div className="bg-white justify-content-center">
                                            <div className="flex">
                                                <div className="inline-block">
                                                    <InstantSearch
                                                        searchClient={searchClient}
                                                        indexName="DEV_LOCAL_BUSINESS"
                                                        routing={true}
                                                    >
                                                        <RefinementList attribute="businessName" />
                                                        <div
                                                            className="overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                                                        >
                                                            <Configure hitsPerPage={60} analytics={false} filters={"city:" + city}/>
                                                            <Hits hitComponent={Hit} transformItems={transformItems}/>
                                                        </div>
                                                    </InstantSearch>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 p-12 sm:col-span-4">
                    {
                        isLoaded ? (
                            <GoogleMap
                                id="marker-example"
                                mapContainerStyle={mapContainerStyle}
                                zoom={12}
                                center={{
                                    lat: lat? lat: 17.6114632,
                                    lng: lng? lng: 78.0590235
                                }}
                            >
                                <Marker
                                    icon={image}
                                    onClick={() => {
                                        handleMarkerClick(business.id, lat? lat: 17.6114632, lng? lng: 78.0590235, business.address);
                                    }}
                                    key={business.id}
                                    position={{
                                        lat: lat? lat: 17.6114632,
                                        lng: lng? lng: 78.0590235
                                    }}
                                    onLoad={() => {
                                        handleMarkerClick(business.id, lat? lat: 17.6114632, lng? lng: 78.0590235, business.address);
                                    }}
                                    draggable={false}

                                >
                                    {isOpen && (
                                        <InfoWindow
                                            onCloseClick={() => {
                                                setIsOpen(false);
                                            }}
                                        >

                                            <>
                                                <h3>{business.businessName}</h3>
                                            </>

                                        </InfoWindow>
                                    )}
                                </Marker>
                            </GoogleMap>
                        ) : <></>
                    }
                    <div className="bg-white rounded-lg border mt-6 justify-content-center text-center justify-items-center m-auto">
                        <h1 className="mb-2 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl dark:text-white text-left ml-3 mt-3">
                            Discussion
                        </h1>
                        <form>

                            <div className="flex flex-col">
                                {
                                    comments.length>0 &&
                                    comments.map(comment =>(
                                        <div className="border rounded-md p-3 ml-3 my-3" key={Math.random()}>
                                            <div className="flex gap-3 items-center">
                                                <img
                                                    src={comment.commentedUserPic}
                                                    className="object-cover w-8 h-8 rounded-full border-2 border-emerald-400 shadow-emerald-400"
                                                    alt=""
                                                />
                                                <h3 className="font-bold">
                                                    {comment?.commentedUserName}
                                                </h3>
                                            </div>
                                            <p className="text-gray-600 mt-2">
                                                {comment.comment}
                                            </p>
                                        </div>
                                    ))
                                }

                            </div>
                            {user && (<>
                                <div className="w-full px-3 my-2">
                                            <textarea
                                                value={commentMessage}
                                                className="bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white"
                                                name="body" placeholder='Type Your Comment' required onChange={(e)=>setCommentMessage(e.target.value)}></textarea>
                                </div>

                                <div className="w-full flex justify-end px-3 mb-3">
                                    <button type='submit'
                                            onClick={(e)=>createComment(e)}
                                            className="px-2.5 py-1.5 rounded-md text-white text-sm bg-green-500"
                                    >Post Comment</button>
                                </div>
                            </>)}

                        </form>
                    </div>
                </div>
            </div>

        </main>
    );
};
