import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
    SearchBox,
    Hits,
    Pagination, Configure, useInstantSearch,
} from "react-instantsearch-hooks-web";
import {Button, Modal} from "flowbite-react";

import {useNavigate} from "react-router-dom";
import {Header} from "../layouts";
import axios from "axios";
import {useState} from "react";
import { Link } from "react-router-dom";
import {UserAuth} from "../../context/AuthContext";

const searchClient = algoliasearch(
    "QYUVWALFXL",
    "6fa402cd2480183ac85034b3a13ff289"
);
export const MyBusinessComponent = () => {
    const navigate = useNavigate();
    const {user} = UserAuth();
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [businessDeleted, setBusinessDeleted] = useState(false);
    // The reason for this delete business id state is that we have bug with delete business method, it somehow takes the
    // id of the last business showing in a single business page.
    const [deletedBusinessId, setDeletedBusinessId] = useState("");
    const deleteMyBusiness = () => {
        axios
            .delete("https://us-central1-flaxdax-backend.cloudfunctions.net/business/"+deletedBusinessId)
            .then((res) => {
                setBusinessDeleted(true);
                setTimeout(()=>{
                    setShowDeleteModel(false);
                    setBusinessDeleted(false);
                    window.location.reload();
                },1500);
            })
            .catch(() => {
                alert("There was an error while retrieving the data");
            });
    };
    function Hit({hit}) {
        return (
            <div key={Math.random()} className="flex justify-center">
                <div className="max-w-sm rounded-lg bg-white shadow-lg">
                    <button onClick={() =>
                        navigate(
                            "/city/" +
                            hit.city +
                            "/" +
                            hit.businessName.replace(/\s+/g, "-"),

                            {
                                state: {
                                    business: hit,
                                },
                            }
                        )
                    }>
                        {hit.image ? (
                            <img
                                className="h-48 w-96 object-cover p-1"
                                src={hit.image}
                                alt=""
                            />
                        ) : (
                            <img
                                className="h-48 w-96 object-cover p-1"
                                src="https://cdn-icons-png.flaticon.com/512/3885/3885073.png"
                                alt=""
                            />
                        )}
                    </button>
                    <div className="p-6">
                        <h5 className="mb-2 text-l font-medium text-gray-900 font-extrabold">
                            {hit.businessName}
                        </h5>
                        {hit.businessName.length <35 && (<br/>)}
                        <p className="mb-4 text-base text-gray-700">{hit.address.length > 20 ? hit.address.slice(0, 20) + '...' : hit.address}</p>
                        <button
                            onClick={() =>
                                navigate(
                                    "/city/" +
                                    hit.city +
                                    "/" +
                                    hit.businessName.replace(/\s+/g, "-"),

                                    {
                                        state: {
                                            business: hit,
                                        },
                                    }
                                )
                            }
                            type="button"
                            className="nline-flex items-center rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        >
                            View
                        </button>
                        <Link to={"/edit" +
                            "/" +
                            hit.businessName.replace(/\s+/g, "-")}>
                            <button
                                className="float-right bg-grey-light hover:bg-grey text-blue-600 font-bold py-2 px-4 rounded inline-flex items-center">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                                </svg>
                                <span>Edit</span>
                            </button>
                        </Link>
                        <button
                            onClick={() =>{
                                setShowDeleteModel(true);
                                setDeletedBusinessId(hit.objectID);
                            }
                            }
                            className="float-right bg-grey-light hover:bg-grey text-red-600 font-bold py-2 px-4 rounded inline-flex items-center">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                            </svg>
                            <span>Delete</span>
                        </button>
                        <Modal show={showDeleteModel}>
                            <Modal.Body>
                                <div className="space-y-6">
                                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                        Are you sure you want to delete the business? <span className="text-red-600 font-extrabold">{hit.businessName}</span></p>
                                    {businessDeleted && (<span>Business successfully deleted.</span>)}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={()=>deleteMyBusiness()} color="failure">
                                    I accept
                                </Button>
                                <Button
                                    color="gray"
                                    onClick={() =>
                                        setShowDeleteModel(false)
                                    }
                                >
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <main>
            <Header/>
            <div className="flex ">
                <div className="hidden h-14 w-28 flex-none md:invisible lg:block">
                    03
                </div>
                <div className="hidden h-14 w-28 flex-none md:invisible lg:block">
                    03
                </div>
                <div className="grow md:mx-8 mt-8">
                    <div className="container mx-auto">
                        <InstantSearch searchClient={searchClient} indexName="DEV_LOCAL_BUSINESS">
                            <SearchBox className="mb-10" placeholder="Search here" />
                            <Configure hitsPerPage={12} analytics={false} filters={"userUid:" + user?.uid}/>

                            <NoResultsBoundary fallback={<NoResults />}>
                                <Hits hitComponent={Hit}/>
                            </NoResultsBoundary>

                            <Pagination className="Pagination" />
                        </InstantSearch>
                    </div>
                </div>
                <div className="hidden h-14 w-28 flex-none sm:invisible md:invisible lg:block">
                    03
                </div>
            </div>
        </main>
    );
};
function NoResultsBoundary({ children, fallback }) {
    const { results } = useInstantSearch();

    if (!results.__isArtificial && results.nbHits === 0) {
        return fallback;
    }

    return children;
}

function NoResults() {
    const { setUiState } = useInstantSearch();

    return (
        <div>
            <p>No results.</p>

            <button
                onClick={() => {
                    setUiState({});
                }}
            >
                Reset search
            </button>
        </div>
    );
}