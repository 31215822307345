import React, {useEffect, useState} from 'react';
import {Header} from "../../layouts";
import {useNavigate} from 'react-router-dom';
import {GoogleButton} from 'react-google-button';
import image from '../../../assets/img/bg_map.png';
import userImg from '../../../assets/img/user.svg';
import {UserAuth} from "../../../context/AuthContext";
import {getAuth, signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";

export const LoginComponent = () => {
    const {googleSignIn, user} = UserAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [loginComponent, setLoginComponent] = useState(true);
    const [forgotPasswordComponent, setForgotPasswordComponent] = useState(false);
    const [password, setPassword] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formSubmissionSucceed, setFormSubmissionSucceed] = useState(false);
    const [formSubmissionFailed, setFormSubmissionFailed] = useState(false);
    const [formSubmissionFailMsg, setFormSubmissionFailMsg] = useState("");
    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    };
    const submitLogin = (e)=>{
        e.preventDefault();
        const auth = getAuth();
        setFormSubmitted(true);
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                setFormSubmitted(false);
                setFormSubmissionSucceed(true);
                setFormSubmissionFailMsg("");
                setFormSubmissionFailed(false);
                console.log(user);
                // ...
            })
            .catch((error) => {
                setFormSubmitted(false);
                setFormSubmissionFailed(true);
                setFormSubmissionSucceed(false);
                setFormSubmissionFailMsg(error.message);
            });
    }
    useEffect(() => {
        console.log(user);
        if (user != null) {
            navigate('/');
        }
    }, [user, navigate]);
    const forgetPassword = (e) => {
        e.preventDefault();
        const actionCodeSettings = {
            url: 'https://your-colony.com/',
            // This must be true.
            handleCodeInApp: true
        };
        const auth = getAuth();
        sendPasswordResetEmail(auth, email, actionCodeSettings)
            .then((res) => {
                window.localStorage.setItem('emailForSignIn', email);
                setFormSubmitted(false);
                setFormSubmissionSucceed(true);
                setFormSubmissionFailMsg("");
                setFormSubmissionFailed(false);
            })
            .catch((error) => {
                console.log(error);
            })
    };
    const loginForm = ()=> <div className="h-screen overflow-hidden flex items-center justify-center" style={{'backgroundImage':`url("${image}")`}}>
            <div className="lg:w-6/12 md:7/12 w-11/12 shadow-3xl pt-36">
                <div className="shadow-lg">
                    <div className="bg-white absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-4 md:p-8 m-2 border-4 border-black-300">
                        <img src={userImg} alt="user"/>
                    </div>
                    <form className="p-12 md:p-24 bg-white  rounded-lg" onSubmit={(e)=>submitLogin(e)}>
                        <div className="flex items-center text-lg mb-6 md:mb-8">
                            <svg className="absolute ml-3" width="24" viewBox="0 0 24 24">
                                <path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/>
                            </svg>
                            <input type="email" id="email" name={email} value={email} onChange={(e)=>setEmail(e.target.value)} className="bg-white pl-12 py-2 md:py-4 focus:outline-none w-full rounded-lg" placeholder="Your email" />
                        </div>
                        <div className="flex items-center text-lg mb-6 md:mb-8">
                            <svg className="absolute ml-3" viewBox="0 0 24 24" width="24">
                                <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"/>
                            </svg>
                            <input type="password" id="password" name={password} value={password} onChange={(e)=>setPassword(e.target.value)} className="bg-white pl-12 py-2 md:py-4 focus:outline-none w-full rounded-lg" placeholder="Password" />
                        </div>
                        <button type="submit" className="from-gray-700 to-gray-900 font-medium p-2 md:p-4 text-white uppercase w-full bg-[#14532D]  rounded-lg">{formSubmitted ? "Submitting..." : "Login"}</button>
                        <button
                            onClick={()=>{
                                setForgotPasswordComponent(true);
                                setLoginComponent(false);
                                setFormSubmissionSucceed(false);
                            }}
                            className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-20">
                            <span>Forgot password</span>
                        </button>
                        {formSubmissionSucceed && (
                            <div
                                className="mb-4 mt-4 rounded-lg bg-blue-100 p-4 text-sm text-blue-700 dark:bg-blue-200 dark:text-blue-800"
                                role="alert"
                            >
                                User successfully loggedin.
                            </div>
                        )}
                        {formSubmissionFailed && (
                            <div
                                className="mb-4 mt-4 rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
                                role="alert"
                            >
                                {formSubmissionFailMsg}
                            </div>
                        )}
                        <div className="mt-10 flex flex-col items-center">
                                <span id="google-login-btn-span">
                            <GoogleButton type="dark" label="Google" onClick={handleGoogleSignIn}/>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    const forgotPasswordForm = ()=> <div className="h-screen overflow-hidden flex items-center justify-center" style={{'backgroundImage':`url("${image}")`}}>
        <div className="lg:w-6/12 md:7/12 w-11/12 shadow-3xl pt-36">
            <div className="shadow-lg">
                <div className="bg-white absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-4 md:p-8 m-2 border-4 border-black-300">
                    <img src={userImg} alt="user"/>
                </div>
                <form className="p-12 md:p-24 bg-white  rounded-lg" onSubmit={(e)=>forgetPassword(e)}>
                    <h1 className="text-center font-extrabold">Forget your password ?</h1>
                    <p className="text-center font-light">Enter your email here and we will send the instructions to create a new password</p>
                    <div className="flex items-center text-lg mb-6 md:mb-8 mt-6">
                        <svg className="absolute ml-3" width="24" viewBox="0 0 24 24">
                            <path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/>
                        </svg>
                        <input type="email" id="email" name={email} value={email} onChange={(e)=>setEmail(e.target.value)} className="bg-white pl-12 py-2 md:py-4 focus:outline-none w-full rounded-lg" placeholder="Your email" />
                    </div>
                    <button type="submit" className="from-gray-700 to-gray-900 font-medium p-2 md:p-4 text-white uppercase w-full bg-[#14532D]  rounded-lg">{formSubmitted ? "Submitting..." : "Submit"}</button>
                    <button
                        onClick={()=>{
                            setForgotPasswordComponent(false);
                            setLoginComponent(true);
                            setFormSubmissionSucceed(false);
                        }}
                        className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-20">
                        <span>Login</span>
                    </button>
                    {formSubmissionSucceed && (
                        <div
                            className="mt-20 rounded-lg bg-blue-100 p-4 text-sm text-blue-700 dark:bg-blue-200 dark:text-blue-800"
                            role="alert"
                        >
                            Email successfully sent with instructions.
                        </div>
                    )}
                    {formSubmissionFailed && (
                        <div
                            className="mb-4 mt-4 rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
                            role="alert"
                        >
                            {formSubmissionFailMsg}
                        </div>
                    )}
                </form>
            </div>
        </div>
    </div>

    return (
        <main>
            <Header/>
            {loginComponent && loginForm()}
            {forgotPasswordComponent && forgotPasswordForm()}
        </main>
    );
};
